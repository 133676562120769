<template>
  <div id="app">
    <yandex-map :settings="settings" :coords="coords" zoom="10">
      <ymap-marker
        :coords="coords"
        marker-id="123123"
        marker-type="placemark"
      />
    </yandex-map>
  </div>
</template>
<script>
import { yandexMap, ymapMarker } from 'vue-yandex-maps'
export default {
  components: { yandexMap, ymapMarker },
  data: () => ({
    settings: {
      apiKey: '5e0b10b7-cc17-4ca5-981d-a48301533091',
      lang: 'ru_RU',
      coordorder: 'latlong',
      version: '2.1'
    },
    coords: [41.295934, 69.1668248],
    placemarks: [
      {
        coords: [54.82896654088406, 39.831893822753904],
        properties: {
          balloonContentBody: 'asdfd',
          balloonContentFooter: '1',
          balloonContentHeader: '1'
        },
        clusterName: '1',
        markerId: '1'
      },
      {
        coords: [54.82796654088306, 39.831893822753904],
        properties: {
          balloonContentBody: 'asdfasdf',
          balloonContentFooter: '1',
          balloonContentHeader: '1'
        },
        clusterName: '1',
        markerId: '2'
      },
      {
        coords: [54.82996654088506, 39.831893822753904],
        properties: {
          balloonContentBody: 'asdfasdf',
          balloonContentFooter: '1',
          balloonContentHeader: '1'
        },
        clusterName: '1',
        markerId: '3'
      }
    ],
    layout:
      '<div>{{ properties.balloonContentHeader }}</div><div>{{ properties.balloonContentBody }}</div><div>{{ properties.balloonContentFooter }}</div>'
  }),
  beforeCreate() {
    this.$store.dispatch('findTruck', this.$route.params.id)
  },
  computed: {
    location() {
      const data = this.$store.state.map.truckLocation
      return data
    }
  }
}
</script>

<style scoped>
html,
#app {
  width: 1100px;
  height: 500px;
  margin: auto;
}

.ymap-container {
  height: 100%;
  width: 100%;
}
</style>
